import { withLoadingIndicator, postMessage, checkAndWriteFeedback, updatePersonBenefitTooltips } from './helpers'

let customMethods = null
let customInit = null
let customData = {}

let timer = null

window.addEventListener('resize', function (event) {
    clearTimeout(timer)
    timer = setTimeout(_ => {
        parent.postMessage({
            type: 'markup.compiled',
            offsetHeight: document.querySelector('html').offsetHeight + 10
        }, '*');
    }, 100)
}, true)

window.basePriceFinder = function () {
    return {
        // Data
        persons: [],
        total: null,

        feedback: {
            persons: {},

            countries: {
                status: null,
                messages: null,
            },
        },

        showTotalDetails: false,

        pendingSubmit: false,

        showSubmitError: false,
        formIsValid: false,
        errorMessage: null,

        countries: '',

        ...customData,

        summaryPriceTitle: null,

        init() {
            let initPerson = this.getNewPerson();
            this.persons.push(initPerson)

            customInit && customInit.call(this)

            const initPersons = window.basePriceFinderConfig.initPersons

            if (Object.keys(initPersons).length >= 1) {
                this.countries = initPersons[1]?.countries_long.toString()

                Object.keys(initPersons).some((key, index) => {
                    const initPerson = initPersons[key]
                    this.updateInitPerson && this.updateInitPerson(initPerson, index)
                });

                setTimeout(_ => {
                    if (this.tagifyCountriesInput) {
                        window.tagifyInputsHolder[this.tagifyCountriesInput].init()
                    }

                    this.$nextTick(_ => {
                        this.onCountryInput()
                    })
                }, 100)

            } else {
                this.updateInitPerson && this.updateInitPerson(initPerson, 0)

                setTimeout(_ => {
                    if (this.tagifyCountriesInput) {
                        window.tagifyInputsHolder[this.tagifyCountriesInput].init();
                    }
                    this.$nextTick(_ => {
                        this.onCountryInput();
                    })
                }, 100);
            }

          this.updateSummaryPriceTitle();

            this.$watch('total', val => {
                if(this.showTotalDetails) {
                    this.showTotalDetails = !!val;
                }
            })

            this.$nextTick(_ => {
                this.postMessage()
            })
        },

        postMessage,

        /**
         * Data functions
         */
        // Get the list of selected countries
        getSelectedCountryList() {
            if (window.basePriceFinderConfig.categoryCode === 'G028') {
                return ['DE'];
            } else {
                const value = this.$refs?.countrySelect?.value || "[]";
                return JSON.parse(value).map(option => option.code);
            }
        },

        updateBasePriceCallback(person, index, { data: { data, status } }) {
            // If this data has already been set (maybe due to an earlier request)
            if (person.selectedInsurance) {
                const insurance = data.insurances.filter(insurance => insurance.uuid === person.selectedInsurance.uuid);

                if (insurance && insurance.length) {
                    person.selectedInsurance.premiumExp = insurance[0].premiumExp
                    // Making sure in case of no fitting insurance (filter is empty), we are serving nothing such as User in FE cannot continue
                } else {
                    person.selectedInsurance = undefined
                }
            }

            this.checkAndWriteFeedback(data, status, person, 'persons')

            person.basePrices.data = data;

            if (person.basePrices.data.maxContractDuration) {
                const maxContractDurationDate = new Date(person.basePrices.data.maxContractDuration)
                const personEndDate = new Date(person.end)

                if (maxContractDurationDate < personEndDate) {
                    person.end = null
                }

            }

            person.basePrices.hasError = false;

            this.$nextTick(_ => {
                updatePersonBenefitTooltips(index)
            })
        },

        // Get the insurance tiers form the backend
        updateBasePricesForPerson(person, index, countries) {
            // Return early if prerequisites are not met
            if (!this.hasPersonPrerequirements(person)) {
                person.basePrices.data = undefined;
                // Resolve directly as data was updated successfully. Not a reason to throw an error.
                return Promise.resolve();
            }

            person.basePrices.isLoading = true;

            return this.updateBasePrice(person, index, countries, this.updateBasePriceCallback)
        },

        updateSummaryPriceTitle() {
            const persons = this.persons.filter(p => p.selectedInsurance !== undefined)
            const translations = window.basePriceFinderConfig.translations
            let title = translations.title.summaryPrice

            if (persons.length > 1) {
                title = translations.title.summaryPriceMultipleUsers
            }

            this.summaryPriceTitle = title.replace('PERSONS_LENGTH', persons.length)
        },

        // Calculate the total price from the current state
        updateTotal() {
            if (this.showSubmitError) {
                this.showSubmitError = this.persons.filter(p => p.selectedInsurance !== undefined).length === 0
            }

            this.formIsValid = this.persons.filter(p => p.selectedInsurance !== undefined).length > 0

            if (!this.persons.every(person => this.isSelectedInsuranceAvailable(person))) {
                this.total = null;
            } else {
                this.total = this.persons.reduce((acc, person) => acc + person.selectedInsurance.premiumExp, 0);
                this.updateSummaryPriceTitle()
            }
        },

        /**
         * Event functions
         */
        onCountryInput() {
            const categoryCode = window.basePriceFinderConfig.categoryCode
            this.countries = this.$refs?.countrySelect?.value
            const countries = this.getSelectedCountryList();
            const updates = [this.updateBasePricesForEachPerson(countries)]

            const methodsByCategoryCode = {
                G007: this.updateRegionCode,
                G008: this.updateRegionCode,
                G014: this.updateCountryZone,
                G016: this.updateRegionCode,
                G019: this.updateRegionCode,
                G020: this.updateRegionCode,
                G028: this.updateRegionCode,
                G030: () => { }, // G030 has no country input. Only a region.
            };

            const updateFunction = methodsByCategoryCode[categoryCode];
            if (updateFunction) {
                updates.push(updateFunction.call(this, countries, categoryCode))
            }

            this.withLoadingIndicator(updates)
                .finally(() => {
                    this.updateTotal();
                });
        },

        onAddNewPerson() {
            this.persons.push(this.getNewPerson());
            this.updateTotal();
            this.$nextTick(_ => {
                const tooltips = document.querySelectorAll(`.tooltip-trigger.person-tooltip[data-tooltip*="person-${this.persons.length - 1}"]`)
                tooltips.forEach(tooltip => {
                    window.addNewTooltip(tooltip)
                });

                this.$nextTick(_ => {
                    this.postMessage()
                })
            })
        },

        onRemovePerson(index) {
            this.persons.splice(index, 1);
            this.updateTotal();

            if (this.showTotalDetails && this.persons.length <= 1) {
                this.showTotalDetails = false
            }

            this.$nextTick(_ => {
                this.postMessage()
            })
        },

        onPersonInput(person, index) {
            this.$nextTick(() => {
                this.withLoadingIndicator([
                    this.updateBasePricesForPerson(person, index, this.getSelectedCountryList())
                ])
                    .finally(() => {
                        this.updateTotal();

                        this.$nextTick(_ => {
                            this.postMessage()
                        })
                    });
            })
        },

        onShowDetails(personBasePrices) {
            personBasePrices.showDetails = !personBasePrices.showDetails
            this.$nextTick(_ => {
                this.postMessage()
            })
        },

        onShowTotalDetails(personBasePrices) {
            this.showTotalDetails = !this.showTotalDetails
            this.$nextTick(_ => {
                this.postMessage()
            })
        },

        /**
         * This is the click event if a insurance is selected.
         *
         * @param $event
         * @param person
         * @param index
         * @param insurance
         */
        onSelectInsurance($event, person, index, insurance) {
            $event.preventDefault();

            if (person.selectedInsurance !== insurance) {
                person.selectedInsurance = insurance;
            } else {
                person.selectedInsurance = undefined;
                this.$nextTick(_ => {
                    const person = document.querySelectorAll('.person')[index]
                    if (person) {
                        const tooltips = person.querySelectorAll('.tooltip-trigger.supported-insurance-tooltip')
                        tooltips.forEach(tooltip => {
                            window.addNewTooltip(tooltip)
                        });
                    }
                })
            }

            this.updateTotal();

            this.$nextTick(_ => {
                this.postMessage()
            })
        },

        /**
         * Utility functions
         */
        // Updates the selectable insurance tiers for all persons
        updateBasePricesForEachPerson(countries) {
            return this.persons.map((person, index) => this.updateBasePricesForPerson(person, index, countries));
        },

        onToggleInsuranceBenefits($event, person) {
            person.basePrices.areCollapsed = !person.basePrices.areCollapsed;

            this.$nextTick(_ => {
                this.postMessage()
            })
        },

        /**
         * Checks whether the selected insurance for a person is still among the insurances retrieved from the
         * API. Returns false, if no insurance is selected, of if a no longer available insurance is selected.
         *
         * @param person
         * @returns {boolean}
         */
        isSelectedInsuranceAvailable(person) {
            return !!person.basePrices.data?.insurances.find(insurance => insurance.uuid === person.selectedInsurance?.uuid);
        },

        withLoadingIndicator,
        checkAndWriteFeedback,

        submitUsers() {
            // Debounce when the user already clicked the button
            if (this.pendingSubmit) {
                return false
            }

            // Trigger and check HTML5 validation; stop if not valid.
            const form = document.querySelector('form[data-form="base-price"]')

            if (form && !form.checkValidity()) {
                form.reportValidity()
                return false
            }

            // Set the flag to debounce all clicks after this one.
            this.pendingSubmit = true

            // Make sure every person has an insurance selected
            if (this.persons.some(p => p.selectedInsurance === undefined)) {
                this.showSubmitError = true
                this.pendingSubmit = false
                return false
            }

            // All seems fine, submit the entered data.
            const preparedUsers = this.prepareSubmitBasePriceUsers(this.persons)

            fetch('/api/v1/submitBasePriceUsers ', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    count_users: preparedUsers.length,
                    users: preparedUsers,
                    categoryCode: window.basePriceFinderConfig.categoryCode,
                    ...window.basePriceFinderConfig.urlParams,
                    locale: window.basePriceFinderConfig.locale,
                }),
            })
                .then(res => res.json())
                .then(({ status, data }) => {
                    if (status === 'success') {
                        window.clearIsDirty()
                        if (window.basePriceFinderConfig.iframeMode) {
                            parent.postMessage({
                                type: 'form.submitted',
                                locationHref: window.basePriceFinderConfig.linkToFormProcess,
                            }, '*');
                        } else {
                            window.location.href = window.basePriceFinderConfig.linkToFormProcess
                        }
                    } else {
                        this.errorMessage = data
                    }
                })
                .finally(_ => {
                    this.pendingSubmit = false
                })
        },

        updateRegionCode() {
            console.error('Must be defined for', window.basePriceFinderConfig.categoryCode)
        },

        updateRegionOfResidence() {
            console.error('Must be defined for', window.basePriceFinderConfig.categoryCode)
        },

        updateCountryZone() {
            console.error('Must be defined for', window.basePriceFinderConfig.categoryCode)
        },

        ...customMethods()
    };
}


if (document.getElementById('basepricefinder')) {
    import(`./contractOptions/${window.basePriceFinderConfig.categoryCode}`).then((contractConfig) => {
        customMethods = contractConfig.methods
        customData = contractConfig.data
        customInit = contractConfig.customInit
        window.Alpine.start()
    })
}
