const withLoadingIndicator = (promises) => {
    promises = [promises].flat(2);

    document.body.style.cursor = 'wait';

    return Promise.all(promises)
        .finally(() => {
            document.body.style.cursor = 'default';
        });
}

const postMessage = () => {
    parent.postMessage({
        type: 'markup.compiled',
        offsetHeight: document.querySelector('html').offsetHeight + 10
    }, '*');
}

const updatePersonBenefitTooltips = (index) => {
    const person = document.querySelectorAll('.person')[index]
    if (person) {
        const tooltips = person.querySelectorAll('.tooltip-trigger.supported-insurance-tooltip')
        tooltips.forEach(tooltip => {
            window.addNewTooltip(tooltip)
        });
    }
}

const checkAndWriteFeedback = function (data, status, person, type) {
    let activeFeedback = this.feedback[type]

    if (person) {
        activeFeedback[person.id] = {
            status: null,
            data: null
        }
        activeFeedback = activeFeedback[person.id]
    }

    activeFeedback.status = status

    if (data.messages?.length >= 1) {
        activeFeedback.messages = data.messages
    }
}

export { withLoadingIndicator, postMessage, updatePersonBenefitTooltips, checkAndWriteFeedback }


// MODAL

document.addEventListener('DOMContentLoaded', () => {
    // Functions to open and close a modal
    function openModal($el) {
        $el.classList.add('is-active');
    }

    function closeModal($el) {
        $el.classList.remove('is-active');
    }

    function closeAllModals() {
        (document.querySelectorAll('.modal') || []).forEach(($modal) => {
            closeModal($modal);
        });
    }

    // Add a click event on buttons to open a specific modal
    (document.querySelectorAll('.js-modal-trigger') || []).forEach(($trigger) => {
        const modal = $trigger.dataset.target;
        const $target = document.getElementById(modal);

        $trigger.addEventListener('click', e => {
            e.preventDefault()
            openModal($target);
        });
    });

    // Add a click event on various child elements to close the parent modal
    (document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button, .contact-modal__close-btn') || []).forEach(($close) => {
        const $target = $close.closest('.modal');

        $close.addEventListener('click', e => {
            e.preventDefault()
            closeModal($target);
        });
    });

    // Add a keyboard event to close all modals
    document.addEventListener('keydown', (event) => {
        const e = event || window.event;

        if (e.keyCode === 27) { // Escape key
            closeAllModals();
        }
    });
});
